<template>
  <div class="dashboard mb-5">
    <page-header
      title="Tableau de bord"
    >
      <div class="flex flex-wrap-reverse gap-2 items-start justify-end">
        <div class="isolate inline-flex rounded-md shadow-sm" style="margin-bottom: 1px">
          <button
            type="button"
            class="relative inline-flex items-center rounded-l-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 focus:z-10"
            @click="substractDate('week', 1)"
          >
            1 semaine
          </button>
          <button
            type="button"
            class="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 focus:z-10"
            @click="substractDate('day', 15)"
          >
            15 jours
          </button>
          <button
            type="button"
            class="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 focus:z-10"
            @click="substractDate('month', 1)"
          >
            1 mois
          </button>
          <button
            type="button"
            class="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 focus:z-10"
            @click="substractDate('month', 3)"
          >
            3 mois
          </button>
          <button
            type="button"
            class="relative -ml-px inline-flex items-center bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 focus:z-10"
            @click="substractDate('month', 6)"
          >
            6 mois
          </button>
          <button
            type="button"
            class="relative -ml-px inline-flex items-center rounded-r-md bg-white px-3 py-2 text-sm text-gray-900 ring-1 ring-gray-300 hover:bg-gray-50 focus:z-10"
            @click="showFull"
          >
            Tout
          </button>
        </div>
        <t-input-group
          class="m-0 text-xs"
          label="Début de période"
          variant="invert"
        >
          <t-datepicker
            v-model="dateStart"
            :maxDate="dateEnd"
            :weekStart="1"
            userFormat="d/m/Y"
            placeholder="Selectionner une date de début de période"
            required
            variant="dashboard"
          />
        </t-input-group>
        <t-input-group
          class="m-0 text-xs"
          variant="invert"
          label="Fin de période"
        >
          <t-datepicker
            v-model="dateEnd"
            :maxDate="today"
            :minDate="dateStart"
            :weekStart="1"
            placeholder="Selectionner une date de fin de période"
            userFormat="d/m/Y"
            required
            variant="dashboard"
          />
        </t-input-group>
      </div>
    </page-header>

    <page-content>
      <section class="grid xl:grid-cols-2 gap-5">
        <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
          <div class="px-4 py-5 sm:px-6 flex items-center justify-between">
            <h3 class="text-lg font-semibold leading-8 text-gray-900">
              Téléchargements
            </h3>
            <div>
              <div
                class="inline-flex items-center gap-x-1.5 rounded-md bg-primary-500 py-1.5 px-2.5 text-xs font-semibold text-white shadow-sm mr-2"
              >
                Total période : {{ loadingGraphs? '' : counters.period.users }}
                <span v-if="loadingGraphs">
                  <i class="fas fa-spinner fa-spin mr-2" />
                </span>
              </div>
              <div
                class="inline-flex items-center gap-x-1.5 rounded-md bg-primary-500 py-1.5 px-2.5 text-xs font-semibold text-white shadow-sm"
              >
                Total : {{ loadingGraphs? '' : counters.total.users }}
                <span v-if="loadingGraphs">
                  <i class="fas fa-spinner fa-spin mr-2" />
                </span>
              </div>
            </div>
          </div>
          <div class="px-4 py-5 sm:p-6">
            <div v-if="!loadingPopularities && popularity.users && popularity.users.length">
              <chart-users
                :chart-data="chartUsersData"
                v-if="loaded"
                :height="200"
                :width="300"
              ></chart-users>
            </div>
            <div v-else-if="loadingPopularities" class="text-center text-sm text-gray-500">Chargement ...</div>
            <div v-else class="text-center text-sm text-gray-500">
              Aucune téléchargement dans cette période
            </div>
          </div>
        </div>

        <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
          <div class="px-5 py-4 sm:px-6">
            <h3 class="text-lg font-semibold leading-8 text-gray-900">
              Utilisateurs Actifs
            </h3>
          </div>
          <div class="px-4 py-5 sm:p-6">
            <div v-if="!loadingPopularities && popularity.logins && popularity.logins.length">
              <chart-logins
                :chart-data="chartLoginsData"
                v-if="loaded"
                :height="200"
                :width="300"
              ></chart-logins>
            </div>
            <div v-else-if="loadingPopularities" class="text-center text-sm text-gray-500">Chargement ...</div>
            <div v-else class="text-center text-sm text-gray-500">
              Aucune connexion dans cette période
            </div>
          </div>
        </div>
      </section>

      <section class="my-5">
        <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
          <div class="px-4 py-5 sm:px-6 flex items-center justify-between">
            <h3 class="text-lg font-semibold leading-8 text-gray-900">
              FAQ - Consultations
            </h3>
            <div>
              <div
                class="inline-flex items-center gap-x-1.5 rounded-md bg-primary-500 py-1.5 px-2.5 text-xs font-semibold text-white shadow-sm mr-2"
              >
                Total sur la période : {{ loadingGraphs ? '' : counters.period.faqViews }}
                <span v-if="loadingGraphs">
                  <i class="fas fa-spinner fa-spin mr-2" />
                </span>
              </div>
              <div
                class="inline-flex items-center gap-x-1.5 rounded-md bg-primary-500 py-1.5 px-2.5 text-xs font-semibold text-white shadow-sm"
              >
                Total depuis le début : {{ loadingGraphs ? '' : counters.total.faqViews }}
                <span v-if="loadingGraphs">
                  <i class="fas fa-spinner fa-spin mr-2" />
                </span>
              </div>
            </div>
          </div>
          <div class="px-4 py-5 sm:p-6">
            <div class="flex"
              v-if="!loadingPopularities && popularity.faqs.length"
            >
              <div class="w-1/3">
                <chart-faq-consultations :chart-data="chartFaqConsultationsData"
                  v-if="loaded" :height="200" :width="300"
                ></chart-faq-consultations>
              </div>
              <div class="w-2/3 ml-4">
                <b-skeleton :animated="true" :count="5"
                  v-if="loadingPopularities"
                >
                  <div class="text-center text-sm text-gray-500">Chargement ...</div>
                </b-skeleton>
                <ul v-if="!loadingPopularities">
                  <li
                    v-for="faq in popularity.faqs"
                    :key="faq.uuid"
                    class="flex items-start"
                  >
                    <div class="w-10 text-center rounded-md bg-primary-200 px-2.5 my-0.5 py-0.5 text-xs font-medium text-primary-900">
                      {{ faq.views }}
                    </div>
                    <div class="px-2 text-sm font-medium text-gray-900">
                      {{ faq.title }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else-if="loadingPopularities" class="text-center text-sm text-gray-500">Chargement ...</div>
            <div v-else class="text-center text-sm text-gray-500">
              Aucune consultation dans cette période
            </div>
          </div>
        </div>
      </section>

      <section class="my-5">
        <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
          <div class="px-4 py-5 sm:px-6 flex items-center justify-between">
            <h3 class="text-lg font-semibold leading-8 text-gray-900">
              Actualités - Lectures
            </h3>
            <div>
              <div class="inline-flex items-center gap-x-1.5 rounded-md bg-primary-500 py-1.5 px-2.5 text-xs font-semibold text-white shadow-sm mr-2">
                Total sur la période : {{ loadingGraphs ? '' : counters.period.newsViews }}
                <span v-if="loadingGraphs">
                  <i class="fas fa-spinner fa-spin mr-2" />
                </span>
              </div>
              <div class="inline-flex items-center gap-x-1.5 rounded-md bg-primary-500 py-1.5 px-2.5 text-xs font-semibold text-white shadow-sm">
                Total depuis le début : {{ loadingGraphs ? '' : counters.total.newsViews }}
                <span v-if="loadingGraphs">
                  <i class="fas fa-spinner fa-spin mr-2" />
                </span>
              </div>
            </div>
          </div>
          <div class="px-4 py-5 sm:p-6">
            <div class="flex"
              v-if="!loadingPopularities && popularity.news.length"
            >
              <div class="w-1/3">
                <chart-news-consultations :chart-data="chartNewsConsultationsData"
                  v-if="loaded" :height="200" :width="300"
                ></chart-news-consultations>
              </div>
              <div class="w-2/3 ml-4">
                <!-- <b-skeleton :animated="true" :count="5"
                  v-if="loadingPopularities"
                >
                <div class="text-center text-sm text-gray-500">Chargement ...</div>
                </b-skeleton> -->
                <ul>
                  <li
                    v-for="article in popularity.news"
                    :key="article.uuid"
                    class="flex items-start"
                  >
                    <div class="w-10 text-center rounded-md bg-primary-200 px-2.5 my-0.5 py-0.5 text-xs font-medium text-primary-900">
                      {{ article.views }}
                    </div>
                    <div class="px-2 text-sm font-medium text-gray-900">
                      {{ article.title }}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-else-if="loadingPopularities" class="text-center text-sm text-gray-500">
              Chargement...
            </div>
            <div v-else class="text-center text-sm text-gray-500">
              Aucune lecture dans cette période
            </div>
          </div>
        </div>
      </section>

      <section class="grid grid-cols-2 gap-5">
        <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
          <div class="px-4 py-4 sm:px-6 flex items-center justify-between">
            <h3 class="text-lg font-semibold leading-8 text-gray-900">
              Mots-clés les plus recherchés
            </h3>
            <button
              type="button"
              class="inline-flex items-center gap-x-1.5 rounded-md bg-primary-500 py-1.5 px-2.5 text-xs font-semibold text-white shadow-sm hover:text-white hover:bg-primary-600"
              :disabled="isLoadingExport"
              @click="downloadSearchExport()"
            >
              <i
                class="fas fa-download"
                :class="{
                  'hidden': isLoadingExport
                }"
              />
              <i
                class="fas fa-spinner fa-spin"
                :class="{
                  'hidden': !isLoadingExport
                }"
              />
              Export
            </button>
          </div>
          <div class="px-4 py-5 sm:p-6">
            <ul v-if="!loadingPopularities && popularity.searches.length">
              <li
                class="flex items-center"
                v-for="search in popularity.searches"
                :key="search.id"
              >
                  <div class="w-10 text-center rounded-md bg-primary-200 px-2.5 my-0.5 py-0.5 text-xs font-medium text-primary-900">
                    {{ search.total }}
                  </div>
                  <div class="px-2 text-sm font-medium text-gray-900">
                    {{ search.query }}
                  </div>
              </li>
            </ul>
            <div v-else-if="loadingPopularities" class="text-center text-sm text-gray-500">
              Chargement...
            </div>
            <div v-else class="text-center text-sm text-gray-500">
              Aucune recherche dans cette période
            </div>
          </div>
        </div>
        <div class="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow">
          <div class="px-5 py-4 sm:px-6">
            <h3 class="text-lg font-semibold leading-8 text-gray-900">
              Catégories de FAQ les plus consultées
            </h3>
          </div>
          <div class="px-4 py-4 sm:p-6">
            <ul v-if="!loadingPopularities && popularity.categories && popularity.categories.length">
              <li
                class="flex items-center"
                v-for="category in popularity.categories"
                :key="category.id"
              >
                  <div class="w-10 text-center rounded-md bg-primary-200 px-2.5 my-0.5 py-0.5 text-xs font-medium text-primary-900">
                    {{ category.views }}
                  </div>
                  <div class="px-2 text-sm font-medium text-gray-900">
                    {{ category.label }}
                  </div>
              </li>
            </ul>
            <div v-else-if="loadingPopularities" class="text-center text-sm text-gray-500">
              Chargement...
            </div>
            <div v-else class="text-center text-sm text-gray-500">
              Aucune recherche dans cette période
            </div>
          </div>
        </div>
      </section>
    </page-content>
  </div>
</template>

<script>
import { parse, parseISO, format, subDays, subMonths, subWeeks } from 'date-fns'
import { mapGetters } from 'vuex'
import fr from 'date-fns/locale/fr'
import PageHeader from '@/components/PageHeader'
import PageContent from '../components/PageContent.vue'
import ChartUsers from '@/components/charts/ChartUsers'
import ChartFaqConsultations from '@/components/charts/ChartFaqConsultations'
import ChartNewsConsultations from '@/components/charts/ChartNewsConsultations'
import ChartLogins from '@/components/charts/ChartLogins'
import { http } from '@/services/api.service'

import { debounce } from 'lodash'

export default {
  data () {
    return {
      loaded: false,
      loadingGraphs: false,
      loadingPopularities: false,
      isLoadingExport: false,
      stats: null,
      graphs: null,
      popularity: {
        faqs: [],
        news: [],
        searches: []
      },
      counters: {
        total: {
          users: 0,
          faqViews: 0,
          newsViews: 0
        },
        period: {
          users: 0,
          faqViews: 0,
          newsViews: 0
        }
      },
      chartUsersData: null,
      chartFaqConsultationsData: null,
      chartNewsConsultationsData: null,
      chartLoginsData: null,
      dateRange: window.localStorage.getItem('dateRange') ?? format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      dateStart: window.localStorage.getItem('dateStart') ?? format(subDays(new Date(), 30), 'yyyy-MM-dd'),
      dateEnd: window.localStorage.getItem('dateEnd') ?? format(new Date(), 'yyyy-MM-dd'),
      today: new Date(),
      domainStartDate: null
    }
  },
  created () {
    this.debounceUpdateRequest = debounce(this.updateRequest, 600)
  },
  beforeMount () {
    // this.getStats()
    this.getCreationDate()
  },
  mounted () {
  },
  computed: {
    ...mapGetters(['domain'])
  },
  watch: {
    domain () {
      // this.getStats()
      this.getCreationDate(this.dateStart === this.domainStartDate)
    },
    dateEnd () {
      window.localStorage.setItem('dateEnd', this.dateEnd)
      this.debounceUpdateRequest()
    },
    dateStart () {
      window.localStorage.setItem('dateStart', this.dateStart)
      this.debounceUpdateRequest()
    }
  },
  components: {
    PageHeader,
    PageContent,
    ChartUsers,
    ChartFaqConsultations,
    ChartNewsConsultations,
    ChartLogins
  },
  methods: {
    getCreationDate (setStart) {
      /*
      if (setStart) {
        this.loaded = false
        this.loadingGraphs = true
        this.loadingPopularities = true
      }
      */
      http.get(`/domain/${this.domain.uuid}/created-at`)
        .then(({ data }) => {
          this.domainStartDate = format(new Date(data), 'yyyy-MM-dd')
          if (setStart) {
            this.dateStart = format(new Date(data), 'yyyy-MM-dd')
          }
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          this.getStats()
          /*
          this.loadingGraphs = false
          this.loaded = true
          this.loadingPopularities = true
          */
        })
    },
    getStats () {
      /*
      this.loaded = false
      this.loadingGraphs = true
      this.loadingPopularities = true
      */
      const dateStart = this.dateStart
      const dateEnd = this.dateEnd
      http.get(`/domain/stats/counter?dateStart=${dateStart}&dateEnd=${dateEnd}`)
        .then(({ data }) => {
          this.counters.total = data
        }).catch(() => {
          this.$buefy.notification.open({
            duration: 7000,
            message: `Nos serveurs semblent avoir rencontrer des difficultés à récupérer les statistiques de votre tableau de bord.`,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
            iconPack: 'fas',
            icon: 'fas fa-server'
          })
        }).finally(() => {
          this.loaded = true
          this.loadingPopularities = false
        })
      http.get(`/domain/stats/graph?dateStart=${dateStart}&dateEnd=${dateEnd}`)
        .then(({ data }) => {
          this.graphs = data.byDay
          this.counters.period = data.total
          this.buildChartUsers(this.graphs)
          this.buildChartFaqConsultations(this.graphs)
          this.buildChartNewsConsultations(this.graphs)
          this.buildChartLogins(this.graphs)
        }).catch(() => {
          this.$buefy.notification.open({
            duration: 7000,
            message: `Nos serveurs semblent avoir rencontrer des difficultés à récupérer les statistiques de votre tableau de bord.`,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
            iconPack: 'fas',
            icon: 'fas fa-server'
          })
        }).finally(() => {
          this.loaded = true
          this.loadingGraphs = false
        })
      http.get(`/domain/stats/popularity?dateStart=${dateStart}&dateEnd=${dateEnd}`)
        .then(({ data }) => {
          this.popularity = data
        }).catch(() => {
          this.$buefy.notification.open({
            duration: 7000,
            message: `Nos serveurs semblent avoir rencontrer des difficultés à récupérer les statistiques de votre tableau de bord.`,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
            iconPack: 'fas',
            icon: 'fas fa-server'
          })
        }).finally(() => {
          this.loaded = true
          this.loadingPopularities = false
        })
    },
    buildChartUsers (graphs) {
      const labels = []
      const data = []
      for (const day in graphs) {
        labels.push(format(parse(day, 'yyyy-MM-dd', new Date()), 'dd/MM', { locale: fr }))
        const incremented = data.slice(-1)[0] === undefined ? graphs[day]['users'] : data.slice(-1)[0] + graphs[day]['users']
        data.push(incremented)
      }
      this.chartUsersData = {
        labels: labels,
        datasets: [
          {
            label: 'Nouveaux utilisateurs',
            borderColor: '#333F50',
            backgroundColor: '#ecf4fe',
            pointBackgroundColor: '#333F50',
            borderWidth: 1,
            radius: 1,
            fill: true,
            data
          }
        ]
      }
    },
    buildChartFaqConsultations (graphs) {
      const labels = []
      const data = []
      for (const day in graphs) {
        labels.push(format(parse(day, 'yyyy-MM-dd', new Date()), 'dd/MM', { locale: fr }))
        data.push(graphs[day]['faqViews'])
      }
      this.chartFaqConsultationsData = {
        labels: labels,
        datasets: [
          {
            label: 'Consultations',
            backgroundColor: '#333F50',
            data
          }
        ]
      }
    },
    buildChartNewsConsultations (graphs) {
      const labels = []
      const data = []
      for (const day in graphs) {
        labels.push(format(parse(day, 'yyyy-MM-dd', new Date()), 'dd/MM', { locale: fr }))
        data.push(graphs[day]['newsViews'])
      }
      this.chartNewsConsultationsData = {
        labels: labels,
        datasets: [
          {
            label: 'Lectures',
            backgroundColor: '#333F50',
            data
          }
        ]
      }
    },
    buildChartLogins (graphs) {
      const labels = []
      const data = []
      for (const day in graphs) {
        labels.push(format(parse(day, 'yyyy-MM-dd', new Date()), 'dd/MM', { locale: fr }))
        data.push(graphs[day]['loginsUniq'])
      }
      this.chartLoginsData = {
        labels: labels,
        datasets: [
          {
            label: 'Connexions',
            backgroundColor: '#333F50',
            data
          }
        ]
      }
    },
    formatDate (date, dateFormat) {
      return format(parseISO(date), dateFormat, {
        locale: fr
      })
    },
    updateRequest () {
      const dateStart = this.dateStart
      const dateEnd = this.dateEnd
      this.loaded = false
      this.loadingGraphs = true
      this.loadingPopularities = true
      http.get(`/domain/stats/popularity?dateStart=${dateStart}&dateEnd=${dateEnd}`)
        .then(({ data }) => {
          this.popularity = data
        }).catch(() => {
          this.$buefy.notification.open({
            duration: 7000,
            message: `Nos serveurs semblent avoir rencontrer des difficultés à récupérer les statistiques de votre tableau de bord.`,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
            iconPack: 'fas',
            icon: 'fas fa-server'
          })
        }).finally(() => {
          this.loaded = true
          this.loadingPopularities = false
        })
      http.get(`/domain/stats/graph?dateStart=${dateStart}&dateEnd=${dateEnd}`)
        .then(({ data }) => {
          this.graphs = data.byDay
          this.counters.period = data.total
          this.buildChartUsers(this.graphs)
          this.buildChartFaqConsultations(this.graphs)
          this.buildChartNewsConsultations(this.graphs)
          this.buildChartLogins(this.graphs)
        }).catch(() => {
          this.$buefy.notification.open({
            duration: 7000,
            message: `Nos serveurs semblent avoir rencontrer des difficultés à récupérer les statistiques de votre tableau de bord.`,
            position: 'is-top-right',
            type: 'is-danger',
            hasIcon: true,
            iconPack: 'fas',
            icon: 'fas fa-server'
          })
        }).finally(() => {
          this.loaded = true
          this.loadingGraphs = false
        })
    },
    downloadSearchExport () {
      this.isLoadingExport = true
      http.get(`/domain/export/search`, { responseType: 'blob' }).then((data) => {
        const url = URL.createObjectURL(data.data)
        var a = document.createElement('a')
        a.href = url
        a.download = `${this.domain.label}-recherches.xlsx`
        a.click()
        a.remove()
        this.isLoadingExport = false
        setTimeout(() => window.URL.revokeObjectURL(url), 100)
      })
    },
    /**
     *
     * @param {('day','week','month')} type type to substract to actual date
     * @param {int} amount amoun to substract to actual day
     */
    substractDate (type, amount) {
      switch (type) {
        case 'day':
          this.dateStart = format(subDays(this.today, amount), 'yyyy-MM-dd')
          break
        case 'week':
          this.dateStart = format(subWeeks(this.today, amount), 'yyyy-MM-dd')
          break
        case 'month':
          this.dateStart = format(subMonths(this.today, amount), 'yyyy-MM-dd')
          break
      }
      this.dateEnd = format(this.today, 'yyyy-MM-dd')
      this.dateRange = [this.dateStart, this.dateEnd]
      this.saveSetting()
    },
    showFull () {
      this.dateStart = this.domainStartDate
      this.dateEnd = format(this.today, 'yyyy-MM-dd')
      this.dateRange = [this.dateStart, this.dateEnd]
      this.saveSetting()
    },
    saveSetting () {
      window.localStorage.setItem('dateStart', this.dateStart)
      window.localStorage.setItem('dateEnd', this.dateEnd)
    }
  }
}
</script>

<style lang="scss">
.topList {
  display: flex;

  .topList-date {
    width: 80px;
    margin-right: 10px;
  }
  .topList-views {
    width: 40px;
    margin-right: 10px;
  }
  .topList-searches {
    width: 80px;
    margin-right: 10px;
  }
}
.table-condensed .table td,
.table-condensed .table th {
  padding: 0;
  border: 0;
}
@media screen and (min-width: 769px), print {
  .page-header .field-label {
    flex-grow: 2;
  }
  .page-header .field-body {
    flex-grow: 2;
  }
}
</style>
