<script>
import { Line } from 'vue-chartjs'
export default {
  extends: Line,
  props: {
    chartData: {
      type: Object,
      default: null
    }
  },
  watch: {
    chartData () {
      this.renderChart(this.chartData, this.options)
    }
  },
  data () {
    return {
      options: {
        scales: {
          yAxes: [{
            type: 'linear',
            ticks: {
              beginAtZero: true,
              stepSize: 10,
              fontSize: 10
            }
          }],
          xAxes: [
            {
              ticks: {
                fontSize: 10
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        }
      }
    }
  },
  mounted () {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
